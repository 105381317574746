<template>
  <div class="animated fadeIn">

    <b-row>

      <b-col v-for="(insurer, index) in this.insurers" lg="4" :key="index">
        <router-link :to="{ name: 'Tables', params: { product: $route.params.product, insurer: insurer.id } }" >
          <div class="card bg-light py-4 text-center">
            <h3 class="p-3 mb-0">{{ insurer.name }}</h3>
          </div>
        </router-link>
      </b-col>

    </b-row><!--/.row-->

  </div>

</template>

<script>

  import axios from '../../../shared/axios/auth'

  export default {
    name: 'Rates',
    data: () => {
      return {
        insurers: []
      }
    },
    methods: {
      getData() {
        axios.get('/insurers/?product=' + this.$route.params.product).then(
          response => {
            this.insurers = response.data.data
            localStorage.setItem('insurers', JSON.stringify(response.data.data))
          }
        ).catch(error => {
              // Unauthorised
              if (error.response.status === 401) {
                this.$store.dispatch('logout');
              }
              console.log('post error');
              console.log(error.response);
          });

        return this.data
      }
    },
    mounted() {
      this.getData()
    }
  }
</script>
